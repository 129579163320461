/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
$black: #2c2d32;

@font-face {
  font-family: "Samsung Sharp Sans";
  src: url("./assets/fonts/SamsungSharpSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/SamsungSharpSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Samsung Sharp Sans";
  src: url("./assets/fonts/SamsungSharpSans.woff2") format("woff2"),
    url("./assets/fonts/SamsungSharpSans.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Samsung Sharp Sans";
  src: url("./assets/fonts/SamsungSharpSans-Medium.woff2") format("woff2"),
    url("./assets/fonts/SamsungSharpSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SamsungOne 400";
  src: url("./assets/fonts/SamsungOne-400.woff2") format("woff2"),
    url("./assets/fonts/SamsungOne-400.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SamsungOne 700";
  src: url("./assets/fonts/SamsungOne-700.woff2") format("woff2"),
    url("./assets/fonts/SamsungOne-700.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

:root {
  --font-family: "Samsung Sharp Sans", sans-serif;
}

body {
  background-color: white;
  color: var(--text-color);
  padding: 0px;
  margin: 0px;
  font-family: "Samsung Sharp Sans", sans-serif;

  h1,
  h2,
  h3 {
    font-family: "Samsung Sharp Sans", sans-serif;
  }

  .p-component {
    font-family: "SamsungOne 400", sans-serif;
  }

  .p-menuitem-text,
  .user-name {
    font-family: "SamsungOne 700", sans-serif;
    font-size: 16px;
  }

  label {
    font-family: "SamsungOne 700", sans-serif;
    font-size: 16px;
  }

  th {
    font-family: "SamsungOne 700", sans-serif;
    font-size: 16px;
  }

  .p-button-label {
    font-family: "SamsungOne 400", sans-serif;
    font-size: 16px;
  }

  p {
    font-family: "SamsungOne 400", sans-serif;
    font-size: 12px;
  }

  pre {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    font-family: "SamsungOne 400", sans-serif !important;
    font-size: 16px !important;
    height: 100% !important;
  }
}

.main-body {
  padding: 3rem 3rem 5rem 3rem;
}

.footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 102;
  text-align: center;
  background-color: #fff;
  filter: drop-shadow(0 0 0.1rem #dddddd);
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-dialog-title {
  font-family: "Samsung Sharp Sans", sans-serif !important;
}

.p-menuitem-text {
  font-family: "Samsung Sharp Sans", sans-serif;
  font-size: 14px;
}

.p-menubar-root-list {
  background-color: $black !important;
}

.p-dialog-content {
  padding-top: 2rem !important;
}

.p-dialog-footer {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;

  button {
    border-radius: 2rem !important;
  }
}
